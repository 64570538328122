import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { 
	NFT_ADDRESS, 
	BUSD_ADDRESS,
	BNB_ADDRESS,
	NETWORK_CHAIN_ID, 
	NETWORK_CHAIN_NAME, 
	RPC_ENDPOINT 
} from '../utils/Constants';
import NFT_ABI from '../abi/PABI.json';
import BUSD_ABI from '../abi/BUSD_ABI.json';
import BNB_ABI from '../abi/BNB_ABI.json';

const initWeb3AndContract = (library = null) => {
	let web3 = null;
	if (!library) {
		web3 = new Web3(`${RPC_ENDPOINT}`);
	} 
	else {
		web3 = library;
	}
	const nftContract = new web3.eth.Contract(NFT_ABI, NFT_ADDRESS);
	const busdContract = new web3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);
	const bnbContract = new web3.eth.Contract(BNB_ABI, BNB_ADDRESS);	

	return {web3, nftContract, busdContract, bnbContract};
}

export const WeiToEth = (amount_in_wei) => {
	const { web3 } = initWeb3AndContract();
	return web3.utils.fromWei(amount_in_wei);
}

export const EthToWei = (amount_in_eth) => {
	const { web3 } = initWeb3AndContract();
	return web3.utils.toWei(amount_in_eth, "ether");
}

// Read Functions

export const bnbBalance = async (library = null, res) => {
	try {
		const { bnbContract } = initWeb3AndContract(library);
		const result = await bnbContract.methods.balanceOf(NFT_ADDRESS).call();
		res(WeiToEth(result));
	}
	catch (e) {
		console.log('bnbBalance: error', e);
	}
}

export const userPools = async (address, library = null, res) => {
	try {
		const { web3, nftContract } = initWeb3AndContract(library);
		const result = await nftContract.methods.userPools(address).call();
		res(result);
	}
	catch (e) {
		console.log('userPools: error', e);
	}	
}

export const trades = async (index, library = null, res) => {
	try {
		const { web3, nftContract } = initWeb3AndContract(library);
		const result = await nftContract.methods.trades(index).call();
		res(result);
	}
	catch (e) {
		console.log('userPools: error', e);
	}	
}

export const getTrades = async (library = null, res) => {
	try {
		const { web3, nftContract } = initWeb3AndContract(library);
		const result = await nftContract.methods.getTrades().call();
		res(result);
	}
	catch (e) {
		console.log('userPools: error', e);
	}	
}

export const tradeCount = async (library = null, res) => {
	try {
		const { web3, nftContract } = initWeb3AndContract(library);
		const result = await nftContract.methods.tradeCount().call();
		res(result);
	}
	catch (e) {
		console.log('userPools: error', e);
	}	
}

export const depositBNB = async (address, amount, library = null, res) => {
	try {
		const { web3, nftContract } = initWeb3AndContract(library);
		const price_in_wei = EthToWei(amount.toString());
		const gasPrice = await web3.eth.getGasPrice();
		const gas = await nftContract.methods.depositBNB().estimateGas({from: address, value: price_in_wei});
		const result = await nftContract.methods.depositBNB().send({from: address, value: price_in_wei, gas, gasPrice});
		if (result.transactionHash) {
			console.log('depositBNB', result);
		}
		else {
			console.log('Sorry, transaction is failed due to errors! Please contact us on Discord.');
		}
	}
	catch (e) {
		console.log('depositBNB: error', e);
	}	
}	

export const depositBUSD = async (address, amount, library = null, res) => {
	try {
		const { web3, nftContract } = initWeb3AndContract(library);
		const price_in_wei = EthToWei(amount.toString());
		const gasPrice = await web3.eth.getGasPrice();
		const gas = await nftContract.methods.depositBUSD(price_in_wei).estimateGas({from: address});
		const result = await nftContract.methods.depositBUSD(price_in_wei).send({from: address, gas, gasPrice});
		if (result.transactionHash) {
			console.log('depositBUSD', result);
		}
		else {
			console.log('Sorry, transaction is failed due to errors! Please contact us on Discord.');
		}
	}
	catch (e) {
		console.log('depositBUSD: error', e);
	}	
}

export const approveBUSD = async (address, library = null, res) => {
	try {
		const { web3, busdContract } = initWeb3AndContract(library);
		const result = await busdContract.methods.approve(NFT_ADDRESS, ethers.constants.MaxUint256).send({from: address});
		if (result.transactionHash) {
			console.log('depositBUSD', result);
			res(true);
		}
		else {
			console.log('Sorry, transaction is failed due to errors! Please contact us on Discord.');
			res(false);
		}
	}
	catch (e) {
		console.log('depositBUSD: error', e);
	}	
}

export const checkApprovedBUSD = async (address, library = null, res) => {
	try {
		const { web3, busdContract } = initWeb3AndContract(library);
		const result = await busdContract.methods.allowance(address, NFT_ADDRESS).call();
		const approvedBalance = new BigNumber(result).toNumber();
		res(approvedBalance > 0);
	}
	catch (e) {
		console.log('depositBUSD: error', e);
	}	
}

export const withdrawBUSD = async (address, amount, library = null, res) => {
	try {
		const { web3, nftContract } = initWeb3AndContract(library);
		const price_in_wei = EthToWei(amount.toString());
		const gasPrice = await web3.eth.getGasPrice();
		const gas = await nftContract.methods.withdrawlAvailablefundsforwithdrawal(price_in_wei).estimateGas({from: address});
		const result = await nftContract.methods.withdrawlAvailablefundsforwithdrawal(price_in_wei).send({from: address, gas, gasPrice});
		if (result.transactionHash) {
			console.log('withdrawBUSD', result);
			res(result);
		}
		else {
			console.log('Sorry, transaction is failed due to errors! Please contact us on Discord.');
			res('Sorry, transaction is failed due to errors! Please contact us on Discord.');
		}
	}
	catch (e) {
		console.log('withdrawBUSD: error', e);
		res(e);
	}	
}	

export const panicWithdrawBUSD = async (address, library = null, res) => {
	try {
		const { web3, nftContract } = initWeb3AndContract(library);
		const gasPrice = await web3.eth.getGasPrice();
		const gas = await nftContract.methods.panicWithdrawBUSD().estimateGas({from: address});
		const result = await nftContract.methods.panicWithdrawBUSD().send({from: address, gas, gasPrice});
		if (result.transactionHash) {
			console.log('panicWithdrawBUSD', result);
			res(result);
		}
		else {
			console.log('Sorry, transaction is failed due to errors! Please contact us on Discord.');
			res('Sorry, transaction is failed due to errors! Please contact us on Discord.');
		}
	}
	catch (e) {
		console.log('panicWithdrawBUSD: error', e);
		res(e);
	}	
}	



























