import React, { useState, useEffect } from "react";
import { useWeb3React } from '@web3-react/core';
import {
  Modal,
  Form,
  Button,
} from 'react-bootstrap';

import { 
  injected, 
} from '../../utils/Connectors';

import { 
  getWalletErrorMessage,
} from '../../utils/Helpers';

import { 
  OWNER_ADDRESS,
} from '../../utils/Constants';

import {
  WeiToEth,
  EthToWei,
  bnbBalance,
  userPools,
  trades,
  getTrades,
  tradeCount,
  depositBNB,
  depositBUSD,
  approveBUSD,
  checkApprovedBUSD,
  withdrawBUSD,
  panicWithdrawBUSD,
} from '../../store/api.js'

import "./transactions.scss";

const Transactions = () => {
  const { account, library, activate, deactivate, connector, error } = useWeb3React();

  const [contractBNB, setContractBNB] = useState(0.0);
  const [amountBUSD, setAmountBUSD] = useState(0.0);
  const [amountBNB, setAmountBNB] = useState(0.0);
  const [userPool, setUserPool] = useState(null);
  const [priceBNBBUSD, setPriceBNBBUSD] = useState(400.0);
  const [depositShow, setDepositShow] = useState(false);
  const [depositSymbol, setDepositSymbol] = useState(0);
  const [amountDeposit, setAmountDeposit] = useState(0.0);
  const [withdrawShow, setWithdrawShow] = useState(false);
  const [panicWithdrawal, setPanicWithdrawal] = useState(true);
  const [amountWithdrawal, setAmountWithdrawal] = useState(0.0);
  const [currentTrade, setCurrentTrade] = useState(null);
  const [pastTrade, setPastTrade] = useState(null);
  const [ageInfo, setAgeInfo] = useState('');
  const [pastAgeInfo, setPastAgeInfo] = useState('');
  const [requestWithdrawal, setRequestWithdrawal] = useState(false);
  const [tradeStartIn, setTradeStartIn] = useState('');
  const [avgEntryPrice, setAvgEntryPrice] = useState(0.0);

  useEffect(() => {

    getPriceRate();

    getUserPool();

    getBNBBalance();

    getLastTrade();

    // setInterval(tradeTimer, 500);

  }, [account]);

  const tradeTimer = () => {
    let date = new Date();
    let hours = date.getUTCHours() % 3;
    let minutes = date.getUTCMinutes();
    let seconds = 3 * 60 * 60 - hours * 60 * 60 - minutes * 60 - date.getUTCSeconds();
    // console.log('tradeTimer', seconds);
    let aHours = parseInt(seconds / 3600);
    let aMinutes = parseInt((seconds - aHours * 3600) / 60);
    let aSeconds = seconds - aHours * 3600 - aMinutes * 60;
    setTradeStartIn(aHours + 'H ' + aMinutes + 'm ' + aSeconds + 's');
  }

  const getPriceRate = () => {
    fetch("https://api.binance.com/api/v3/ticker/price?symbol=BNBBUSD")
      .then(res => res.json())
      .then(
        (result) => {
          // console.log('getPriceRate', result);
          setPriceBNBBUSD(parseFloat(result.price));
        },
        (error) => {
          console.log('getPriceRate - BUSD', error);
        }
      );
  }

  const handleDeposit = () => {
    setAmountDeposit(0);
    setDepositSymbol(0);
    setDepositShow(true);
  };

  const handlePanicWithdrawal = () => {
    setPanicWithdrawal(true);
    setAmountWithdrawal(0);

    if (requestWithdrawal) {
      window.alert('Wait a moment. Your withdrawal is pending...');
      return;  
    }
    setWithdrawShow(true);
  };

  const handleWithdrawal = () => {
    setPanicWithdrawal(false);
    setAmountWithdrawal(0);

    if (requestWithdrawal) {
      window.alert('Wait a moment. Your withdrawal is pending...');
      return;  
    }
    setWithdrawShow(true);
  };

  const handleWalletConnect = (_connetor) => {
    activate(_connetor);
  };  

  // console.log('account', account);
  const getUserPool = () => {
    userPools(account, library, (result) => {
      // console.log('userPools - result', result);
      let busd = WeiToEth(result.balanceBUSD);
      let bnb = WeiToEth(result.balanceBNB);
      // let aBUSD = (parseFloat(busd) + parseFloat(bnb) * parseFloat(priceBNBBUSD)).toFixed(2);
      // let aBNB = (parseFloat(bnb) + parseFloat(busd) / parseFloat(priceBNBBUSD)).toFixed(2);
      setAmountBUSD(parseFloat(busd));
      setAmountBNB(parseFloat(bnb));
    });
  }   

  const getBNBBalance = () => {
    if (!account) {// || account != OWNER_ADDRESS) {
      return;
    }

    bnbBalance(library, (result) => {
      console.log('bnbBalance', result);
      setContractBNB(parseFloat(result).toFixed(4));
    });
  } 

  // Trade History
  const getLastTrade = () => {
    getTrades(library, (res) => {
      // console.log('getTrades', res);
      if (res && Array.isArray(res) && res.length > 0) {
        let tradeCount = res.length;
        let lastTrade = res[tradeCount - 1];
        // console.log('lastTrade', lastTrade);
        setPastTrade(lastTrade);
        setCurrentTrade(null);

        let days = parseInt((parseInt(Date.now() / 1000) - parseInt(lastTrade.timestamp)) / 86400);
        if (days > 0) {
          setPastAgeInfo(days + 'D');          
          setAgeInfo('');
        }
        else {
          let hours = parseInt((parseInt(Date.now() / 1000) - parseInt(lastTrade.timestamp)) / 3600);
          if (hours == 0) {
            let minutes = parseInt((parseInt(Date.now() / 1000) - parseInt(lastTrade.timestamp)) / 60);
            // setAgeInfo(minutes + 'm');
            setPastAgeInfo(minutes + 'm');
            // setCurrentTrade(lastTrade);

            // Get Past Trade
            // if (tradeCount > 1) {              
            //   let pastTrade = res[tradeCount - 2];
            //   let days1 = parseInt((parseInt(Date.now() / 1000) - parseInt(pastTrade.timestamp)) / 86400);
            //   let hours1 = parseInt((parseInt(Date.now() / 1000) - parseInt(pastTrade.timestamp)) / 3600);
            //   let minutes1 = parseInt((parseInt(Date.now() / 1000) - parseInt(pastTrade.timestamp)) / 60);
            //   if (days1 > 0) {
            //     setPastAgeInfo(days1 + 'D');
            //     setPastTrade(pastTrade);
            //   }
            //   else if (hours1 > 0) {
            //     setPastAgeInfo(hours1 + 'H');
            //     setPastTrade(pastTrade);          
            //   }
            //   else if (minutes1 > 0) {
            //     setPastAgeInfo(minutes1 + 'm');
            //     setPastTrade(pastTrade);          
            //   }
            // }
          }
          else {
            setPastAgeInfo(hours + 'H');
            // setPastTrade(lastTrade);
            // setCurrentTrade(null);
            setAgeInfo('');
          }
        }

        // Calculate the Average Entry Price
        let totalBNB = 0;
        let totalBUSD = 0;
        for (var i = 0; i < res.length; i++) {
          const tradeInfo = res[i];
          if (tradeInfo.profit == 0 && tradeInfo.admin == 0) {
            totalBNB += parseInt(tradeInfo.bnb);
            totalBUSD += parseInt(tradeInfo.busd);
          }
          else {
            totalBNB = 0;
            totalBUSD = 0; 
          }
        }
        if (totalBNB > 0) {
          setAvgEntryPrice(totalBUSD / totalBNB);
        }
      }
    });
  }

  // BUSD Approve Status
  // checkApprovedBUSD(account, library, (result) => {
  //   console.log('checkApprovedBUSD', result);
  // });

  const onDeposit = () => {
    checkApprovedBUSD(account, library, (result) => {
      console.log('checkApprovedBUSD', result);
      if (result) {
        if (depositSymbol === 1) { // BNB
          depositBNB(account, amountDeposit, library, (result) => {
            
          });
        }
        else if (depositSymbol === 2) { // BUSD
          depositBUSD(account, amountDeposit, library, (result) => {
            
          });
        }
      }
      else { // Aprove BUSD Transfer
        approveBUSD(account, library, (result) => {
          if (result) {
            onDeposit();
          }
        });
      }
    });    
  }

  const onWithdraw = () => {
    console.log('onWithdraw', amountWithdrawal);
    if (panicWithdrawal) { // Panic Withdrawal
      panicWithdrawBUSD(account, library, (result) => {
        setRequestWithdrawal(false);
      });
    }
    else { // Request Withdrawal
      withdrawBUSD(account, amountWithdrawal, library, (result) => {
        setRequestWithdrawal(false);
      });
    }
  }

  if (!account) {
    return (
      <div className="transaction">
        <div className="connect__wallet">
          <h2>
            Wallet not connected
          </h2>
          {
            !error && (
              <p>
                Please connect your wallet to access the Dashboard.
              </p>
            )
          }          
          {
            error && (
              <p className="text-danger">
                { getWalletErrorMessage(error) }
              </p>
            )
          }
          <button className="deposit" onClick={() => handleWalletConnect(injected)}>
            Connect Wallet
          </button>
        </div>
      </div>  
    )
  }  

  return (
    <div className="transaction">      
      <div className="transaction__header">
        <button className="deposit" onClick={handleDeposit}>
          Deposit (BNB or BUSD)
        </button>
        <button className="panic" onClick={handlePanicWithdrawal}>
          Panic Withdrawal
        </button>
        <button className="request" onClick={handleWithdrawal}>
          Request Withdrawal
        </button>
      </div>
      <div className='info-line'>
        <p 
          className="timer"
          style={{
            visibility: 'hidden'
          }}
        >
          {`Next trade will start in: ${tradeStartIn}`}
        </p>
        <p className="notie">Requested withdrawal: {requestWithdrawal? 'Yes': 'No'}</p>        
      </div>      
      <div className="transaction__body">
        <h2>
          Available funds for withdrawal<br/>
          <span className="color"> ${parseFloat(amountBUSD).toFixed(2)} </span>/ BUSD
        </h2>
        <div className="tarde__list">
          <h3>Current trade</h3>
          <div className="all_cards">
            <div className="card">
              <h4>Currency</h4>
              <p>BNB/BUSD</p>
            </div>
            <div className="card">
              <h4>Amount</h4>
              <p>
                <span className="blue__color">
                  { 
                    // account == OWNER_ADDRESS ?
                    // parseFloat(contractBNB).toFixed(5):
                    parseFloat(amountBNB).toFixed(5)
                  }
                </span> BNB
              </p>
            </div>
            { /*<div className="card">
              <h4>Cost</h4>
              <p>{ currentTrade? parseFloat(WeiToEth(currentTrade.busd)).toFixed(5): '0'} BUSD</p>
            </div> */}
            <div className="card">
              <h4>Avg entry price</h4>
              <p>{ `$${parseFloat(avgEntryPrice).toFixed(2)}` }</p>
            </div>
            <div className="card">
              <h4>Last profit</h4>
              <p className="color">{ pastTrade? `${parseFloat(pastTrade.profit / 10000).toFixed(2)}%`: '0.00%' }</p>
            </div>
            <div className="card">
              <h4>Age info</h4>
              { /*<p>{ currentTrade? ageInfo: '0D'}</p> */}
              <p>{ pastAgeInfo }</p>
            </div>
          </div>
        </div>
      </div>
      <div className="transaction__footer">
        <h4>Your past trade</h4>
        <p className="info">
          {
            pastTrade ? 
            `BNB/BUSD | Amount ${parseFloat(WeiToEth(pastTrade.bnb)).toFixed(5)} BNB | Cost ${parseFloat(WeiToEth(pastTrade.busd)).toFixed(5)} BUSD | Closing Price $${parseFloat(parseFloat(pastTrade.busd)/parseFloat(pastTrade.bnb)).toFixed(2)} | Profit ${parseFloat(pastTrade.profit / 10000).toFixed(2)}% | Since last trade ${pastAgeInfo}` :
            'Currency | Amount | Cost | profit | Since last trade.'
          }
        </p>
      </div>

      <Modal
        show={depositShow}
        onHide={() => {
          setDepositShow(false);
        }}
        backdrop={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-black'>Deposit BNB or BUSD</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Check
                inline
                className='text-black'
                label='BNB'
                type='radio'
                onChange={() => {
                  setDepositSymbol(1);
                }}
                id='inline-radio-1'
                name='symbols'
                required
              />
              <Form.Check
                inline
                className='text-black'
                label='BUSD'
                type='radio'
                onChange={() => {
                  setDepositSymbol(2);
                }}
                id='inline-radio-2'
                name='symbols'
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className='text-black'>Amount</Form.Label>
              <Form.Control
                type='number'
                placeholder='0.0'
                autoFocus
                onChange={(e) => {
                  setAmountDeposit(parseFloat(e.target.value));
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button 
            onClick={() => {
              if (depositSymbol === 0) {
                window.alert('Please select BNB or BUSD to deposit.');
                return;
              }
              if (amountDeposit <= 0) {
                window.alert('The deposit amount is invalid.');
                return; 
              }
              onDeposit();
              setDepositShow(false);
            }}
          >
            Deposit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={withdrawShow}
        onHide={() => {
          setWithdrawShow(false);
        }}
        backdrop={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-black'>{panicWithdrawal? 'Panic': 'Request'} Withdrawal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>            
            <Form.Group>
              {panicWithdrawal && (
                <Form.Label className='text-danger'>You will lose 20% of your share!</Form.Label>
              )}
              {!panicWithdrawal && (
                <Form.Label className='text-black'>BUSD Amount</Form.Label>
              )}

              {!panicWithdrawal && (
                <Form.Control
                  type='number'
                  placeholder='0.0'
                  autoFocus
                  onChange={(e) => {
                    setAmountWithdrawal(parseFloat(e.target.value));
                  }}
                />
              )}                
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button 
            onClick={() => {
              if (!panicWithdrawal && amountWithdrawal <= 0) {
                window.alert('The withdrawal amount is invalid.');
                return; 
              }
              onWithdraw();
              setWithdrawShow(false);
              setRequestWithdrawal(true);
            }}
          >
            Withdraw
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Transactions;
