

/** mainnet **/
export const NETWORK_CHAIN_ID = 56; // BNB Main 
export const NETWORK_CHAIN_NAME = 'Mainnet';
export const RPC_ENDPOINT  = 'https://bsc-dataseed.binance.org/';
export const NFT_ADDRESS   = '0x0A8fa296244175Ebf66EF848aCD1161D95e873CF'; // Contract Address
export const BUSD_ADDRESS  = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'; // 
export const BNB_ADDRESS   = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'; // 
export const OWNER_ADDRESS = '0xbdB27a9D09EBf32Dc27d9F339590f98fF6E99c6f'; // Owner Address

/** testnet **/
// export const NETWORK_CHAIN_ID = 97; // BNB Test 
// export const NETWORK_CHAIN_NAME = 'Testnet';
// export const RPC_ENDPOINT = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
// export const NFT_ADDRESS = '0xB440213694fcC9E655A54948A8888D299E2C283C'; // Smart Contract
// export const BUSD_ADDRESS = "0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7";








