import React from 'react';
import ReactDOM from 'react-dom/client';

import Web3 from 'web3';
import { Web3ReactProvider } from '@web3-react/core';

import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

const getLibrary = (provider) => {
  return new Web3(provider);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Web3ReactProvider getLibrary={getLibrary}>
      <App / >
    </Web3ReactProvider>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <Web3ReactProvider getLibrary={getLibrary}>
//       <App / >
//     </Web3ReactProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );




