
import {Analysis,Chart,Profile,Market,Faq,Transactions} from "./components"

function App() {
  return (
    <div className="app">
      <div className="main__container">
        <div className="analysis__transactions">
          <Transactions />
        </div>
        {/* <div className="analysis__transactions">
          <Analysis />
          <Transactions />
        </div>

        <div className="chart__profile">
          <Chart />
          <Profile />
        </div>

        <div 
          className="market__overview"
          style={{
            'visibility': 'hidden',        
          }}
        >
          <Market />
        </div>
        <Faq /> */ }
      </div>
    </div>
  );
}

export default App;
